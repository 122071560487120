import * as React from 'react';
import { useFormatMessage } from 'evl-ui-lib';
import { EvlComingSoon } from 'evl-ui-components';

interface ComingSoonProps {}

const ComingSoon: React.FC<ComingSoonProps> = ({}) => {
  const translate = useFormatMessage();
  return <EvlComingSoon logoUrl={'/static/images/EL_logo_dark.svg'} text={translate('app.comingSoon')} />;
};

export default ComingSoon;
